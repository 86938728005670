import React, {useRef} from 'react';
import './App.css';
import {TwitchEmbed, TwitchPlayer} from 'react-twitch-embed';
import SpooderLogo from "./icons/spooder-logo.svg";
import Overlay from './Overlay';
import ReactModal from 'react-modal-resizable-draggable';
import defaultIcon from './icons/spider-solid.svg';

class App extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			pluginOpen:false,
			plugins:{},
			activeOverlays:[],
			fullscreen:false,
			showChat:true
		};
	}

	embed = React.createRef();

	componentDidMount(){
		console.log("COMPONENT MOUNTED");
		this.getPublicInfo();
	}

	getPublicInfo(){
		fetch("/public/data", {
			method:"GET",
			headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
		})
		.then(response => response.json())
		.then(data => {
			console.log("PUBLIC DATA", data);
			this.setState(Object.assign(this.state, data));
		});
	}

	getAssetPath = (pluginName,asset)=>{
		return window.location.origin+"/assets/"+pluginName+"/"+asset;
	}

	getIconPath = (pluginName)=>{
		return window.location.origin+"/icons/"+pluginName+".png";
	}

	getPlugins(){
		fetch("/public/data", {
			method:"GET",
			headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
		})
		.then(response => response.json())
		.then(obj => {
			console.log(obj);
			this.setState(Object.assign(this.state, {plugins:obj.data}));
		});
	}

	onHandleReady(e){
		console.log(e);
		this.embed.current = e;
	}

	onTwitchAuth = (e, d) => {
		console.log("Twitch auth", d);
		this.setState(Object.assign(this.state, {twitch:{
			displayName: d.displayName,
			id: d.id,
			profilepic:d.profileImageURL
		}}));
	}

	gotoShare(){
		window.location.href = window.location.origin+"/share";
	}

	gotoMod(){
		window.location.href = window.location.origin+"/mod";
	}

	togglePlugins = () => {
		this.setState(Object.assign(this.state, {pluginOpen:!this.state.pluginOpen}));
	}

	showPlugins = () => {
		this.setState(Object.assign(this.state, {pluginOpen:true}));
	}

	hidePlugins = () => {
		this.setState(Object.assign(this.state, {pluginOpen:false}));
	}

	addOverlay = (pluginName) => {
		this.hidePlugins();
		let newOverlays = Object.assign([], this.state.activeOverlays);
		if(!newOverlays.includes(pluginName)){
			newOverlays.push(pluginName);
		}
		this.setState(Object.assign(this.state, {activeOverlays:newOverlays}));
	}

	removeOverlay = (pluginName) => {
		let newOverlays = Object.assign([], this.state.activeOverlays);
		if(newOverlays.includes(pluginName)){
			newOverlays.splice(newOverlays.indexOf(pluginName), 1);
		}
		this.setState(Object.assign(this.state, {activeOverlays:newOverlays}));
	}

	imgError = (e) => {
		e.currentTarget.src = defaultIcon;
	}

	render(){

		let navigation = <div className='navigation'>
			<div className="nav-bot-name">{this.state.botName}@{this.state.homeChannel}</div>
			<div className="nav-login-actions">
				<div className="link-button share" onClick={this.gotoShare}>Share</div>
				<div className="link-button mod" onClick={this.gotoMod}>Mod</div>
			</div>
		</div>;

		let twitchEmbed = null;
		if(this.state.homeChannel != null){
			if(this.state.showChat == true){
				twitchEmbed = <TwitchEmbed channel={this.state.homeChannel} width="100%" height="80vh" autoplay muted withChat allowFullscreen="false" darkMode={true} onVideoReady={this.onHandleReady} onAuthenticate={this.onTwitchAuth}/>
			}else{
				twitchEmbed = <TwitchPlayer channel={this.state.homeChannel} width="100%" height="80vh" autoplay muted allowFullscreen="false" darkMode={true} onVideoReady={this.onHandleReady} onAuthenticate={this.onTwitchAuth}/>
			}
		}
		let pluginIcons = [];
		for(let p in this.state.plugins){
			pluginIcons.push(
				<div className="plugin-icon" onClick={()=>this.addOverlay(p)}>
					<img src={this.getIconPath(p)} height={50} width={50} onError={this.imgError}/>
					{this.state.plugins[p]}
				</div>
			)
		}

		let overlays = [];
		for(let o in this.state.activeOverlays){
			overlays.push(
				<Overlay 
				title={this.state.plugins[this.state.activeOverlays[o]]} 
				isOpen={true} 
				onClose={() => this.removeOverlay(this.state.activeOverlays[o])} 
				twitch={this.state.twitch}
				url={`https://lon.spooder.me/plugin/${this.state.activeOverlays[o]}`}
				/>
			)
		}
		console.log("TWITCH", this.state.twitch);
		let stream = <div className="stream">
						<div className={"plugin-ui"}>
							<div className={"plugin-grid"+(this.state.pluginOpen?"":" hidden")}>
								{pluginIcons}
							</div>
						</div>
						<div key={this.twitch?.id+this.state.activeOverlays.length} className={"overlays"}>
							{overlays}
						</div>
						
						<div className="twitch-embed">
							{twitchEmbed}
						</div>
					</div>;
		return(
			<div className="app">
				{navigation}
				{stream}
				<div className={"plugin-header"} onClick={this.togglePlugins}>^</div>
			</div>
		);
	}
}

export default App;
