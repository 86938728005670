import { Fragment, Component } from "react";
import "./Overlay.css";
import Draggable from 'react-draggable';
import {Resizable, ResizableBox} from 'react-resizable';

class Overlay extends Component {
    constructor(props){
        super(props);
        this.state = {
            title:props.title,
            isOpen:props.isOpen,
            onClose:props.onClose,
            url:props.url,
            twitch:props.twitch,
            isResizing: false,
            position: { x: 0, y: 0 },
            isPortrait:this.props.portrait,
            minmized:false,
            size: { label:"S", width: 300, height: 200 }
        }

        this.setSize = this.setSize.bind(this);
    }

    setSize(size){
      let newSize = {width:0, height:0};
      if(this.state.isPortrait == true){
        switch(size.toUpperCase()){
          case "L":
            newSize.width = 720;
            newSize.height = 1280;
          break;
          case "M":
            newSize.width = 480;
            newSize.height = 640;
          break;
          case "S":
            newSize.width = 240;
            newSize.height = 360;
          break;
        }
      }else{
        switch(size.toUpperCase()){
          case "L":
            newSize.height = 720;
            newSize.width = 1280;
          break;
          case "M":
            newSize.height = 480;
            newSize.width = 640;
          break;
          case "S":
            newSize.height = 240;
            newSize.width = 360;
          break;
        }
      }
      this.setState(Object.assign(this.state, {size:newSize}));
    }

    minimize(){
      this.setState(Object.assign(this.state, {minimized:!this.state.minimized}));
    }

    render(){
        return (
          <Draggable handle=".overlay-header" >
            <div style={this.state.minimized==false?{"width":this.state.size.width, "height":this.state.size.height}:null} className="overlay" >
                <div className="overlay-header">
                  {this.state.title}
                  <div className="overlay__controls">
                    
                    <button
                    className="overlay__minimize"
                    type="button"
                    onClick={this.minimize.bind(this)}
                  />
                  <button
                    className="overlay__close"
                    type="button"
                    onClick={this.state.onClose}
                  />
                </div>
                </div>
                
                <div className="overlay__container" style={this.state.minimized==true?{"display":"none"}:null}>
                  <iframe src={this.state.url+`?t=${this.state.twitch?.id}`} height={this.state.size.height-50} width={this.state.size.width} style={{"overflow":"hidden"}}></iframe>
                </div>
                <div className="overlay__controls_bottom">
                  <button className="overlay__resize" type="button" onClick={()=>this.setSize("S")}>S</button>
                  <button className="overlay__resize" type="button" onClick={()=>this.setSize("M")}>M</button>
                  <button className="overlay__resize" type="button" onClick={()=>this.setSize("L")}>L</button>
                </div>
            </div>
          </Draggable>
          );
    }
  
}

export default Overlay;